<template>
	<div class="floor-style-3">
		<div class="scenes-floor-wrap">
			<div id="scenes-floor" class="scenes-floor">
				<div class="scenes-floor-bg"
					style="background-image: url(&quot;https://m.360buyimg.com/babel/jfs/t1/190911/9/32836/17431/6401be6dF93d5b6f9/ae7d1722156f7d42.jpg&quot;);">
					<div class="w">
						<div class="scenes-floor-inner">
							<div class="jdb-title">
								<div class="title">企采场景购</div>
							</div>
							<div class="scenes-list">
								<div class="scenes-tabs">
									<!-- active -->
									<div  @mouseover="onHover(1)">
										<div v-if="1==nows_id" class="scenes-tab active" >
											金融专区
										</div>
										<div v-else class="scenes-tab ">
											金融专区
										</div>
									</div>
									
									<div  @mouseover="onHover(2)">
										<div v-if="2==nows_id" class="scenes-tab active" >
											政企专区
										</div>
										<div v-else class="scenes-tab ">
											政企专区
										</div>
									</div>
									<div  @mouseover="onHover(3)">
										<div v-if="3==nows_id" class="scenes-tab active" >
											军需物资
										</div>
										<div v-else class="scenes-tab ">
											军需物资
										</div>
									</div>
								</div>
								<div class="scenes-product-wrapper">
									<div class="jdb-sku-wrapper" v-for="(item, index) in goodsList" :key="index" :title="item.goods_name" @click="goSku(item.sku_id)">
										<div class="jdb-sku-img">
											<div class="jdb-img-box" style="width: 160px; height: 160px;">
												<img alt="商品图片" :src="$img(item.sku_image)" @error="imageError(index)" />
											</div>
										</div>
										<div class="jdb-sku-name">
											{{ item.goods_name }}
										</div>
										<div class="scenes-price-wrap">
											<div class="jdb-price-box">
												<div class="jd-price jdb-red-price">
													<span class="price">{{ item.discount_price }}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import {
		goodsSkuPage
	} from "@/api/goods/goods"
	export default {
		name: 'floor-style-3',
		props: {
			data: {
				type: Object
			}
		},
		data() { 
			return {
				goodsList:[],
				category_id:0,
				nows_id:1
			};
		},
		created() {   
			this.getIndexGoodsList();
		},
		computed: {
			...mapGetters(['defaultGoodsImage'])
		},
		methods: {
			goSku(skuId) {
				this.$router.pushToTab('/sku-' + skuId);
			},
			imageErrorRight(index) {
				this.data.value.rightGoodsList.value.list[index].sku_image = this.defaultGoodsImage;
			},
			imageErrorBottom(index) {
				this.data.value.bottomGoodsList.value.list[index].sku_image = this.defaultGoodsImage;
			},
			onHover(e)
			{ 
				
				this.nows_id = e
				this.getIndexGoodsList();
			},
			getIndexGoodsList() {
				this.goodsList=[]
				goodsSkuPage({ 
					nows_id:this.nows_id,
					page_size:4,
					})
					.then(res => {
						this.goodsList = res.data.list;
					})
					.catch(err => {
					})
			},
		}
	};
</script>

<style lang="scss">
	@import 'floor-style-3.css';
	.cart{ width:135px !important}
</style>