import Config from "@/utils/config"
import {
	login
} from "@/api/auth/login"
import {
	adList
} from "@/api/website"
import {
	noticesList
} from "@/api/cms/notice"
import {
	floors,
	floatLayer,
	apiDefaultSearchWords,
	GiftIfi,
	GiftIfiProduct
} from "@/api/pc"
import {
	mapGetters
} from "vuex"
import {
	couponList,
	receiveCoupon
} from "@/api/coupon"
import {
	goodsPage,
	timeList
} from "@/api/seckill"
import {
	goodsSkuPage
} from "@/api/goods/goods"
import CountDown from "vue2-countdown"
import {
	brandList,
	goodsRecommend,
	Topicgoods
} from '@/api/goods/goods';
import { tree } from "@/api/goods/goodscategory"

import request from "@/utils/http"
export default {
	name: "index",
	components: {
		CountDown
	},
	data: () => {
		return {
			activities_id: 0,
			loadingAd: true,
			loadingFloor: true,
			indexBandImages: '',
			adList: [],
			adLeftList: [],
			brandList: [],
			indexbrandleftList: [],
			adRightList: [],
			noticeList: [],
			couponList: [],
			floorList: [],
			floatLayer: {
				is_show: false,
				link: {
					url: ""
				}
			},

			indexFloatLayerNum: 0,
			shopApplyUrl: Config.baseUrl + "/shop/login/register",
			shopCenterUrl: Config.baseUrl + "/shop/index/index",
			storeUrl: Config.baseUrl + "/store",
			isSub: false,
			siteId: 0,
			listData: [],
			seckillTimeMachine: {
				currentTime: 0,
				startTime: 0,
				endTime: 0
			},
			seckillText: "距离结束",
			backgroundColor: "", // 顶部banner背景颜色
			// 悬浮搜索
			searchType: "goods",
			searchTypeText: "商品",
			keyword: "",
			defaultSearchWords: "",
			isShow: false,
			goodsList: [],
			activities: {},
			gift_ifi: [],
			gift_lists: [],
			recommend_lists: {},
			jiand_lists: {},
			adChoiceList: [],
			Topicgoodslists: [],
			currentPage: 1,
			pageSize: 9,
			total: 50,
			zqye_lists:[],
			jun_lists:[],
			jinr_lists:[],
			yi_category_lists:[],
			yi_category_id:0,
			recommended_lists:[]
		}
	},
	watch: {
		searchType() {
			this.searchTypeText = this.searchType == "goods" ? "商品" : "店铺"
		}
	},
	created() {

		this.getAdList()
		this.getIndexbrandleftList()
		this.getNotice()
		this.getBigAdList()
		this.getSmallAdList()
		this.getBrandList()
		this.getGiftIfi()
		this.getrecommend()
		this.getTopicgoods()
		if (this.city.id > 0) {
			this.getIndexGoodsList();
		} else {
			this.getFloors();
			this.getFloatLayer();
			if (this.addonIsExit && this.addonIsExit.seckill == 1) {
				this.getTimeList();
			}
			this.getCanReceiveCouponQuery();
		}

		// this.getFloatLayer()
		this.$store.dispatch("site/siteInfo") // 站点信息
		this.getDefaultSearchWords() // 默认搜索类型
		// if(this.addonIsExit && this.addonIsExit.seckill == 1){
		// 	this.getTimeList()
		// }
		// this.getCanReceiveCouponQuery()
		this.indexBandImages = "background: url('" + Config.baseUrl + 'upload/common/images/index-band-images.png' +
			"')";

	},
	mounted() {
		window.addEventListener("scroll", this.handleScroll)
	},
	computed: {
		...mapGetters(["defaultHeadImage", "addonIsExit", "defaultGoodsImage", "member", "siteInfo", "cartCount",
			"city"
		]),
		optionLeft() {
			return {
				direction: 2,
				limitMoveNum: 1
			}
		},

	},
	methods: {
		//todeo 待定
		getTopicgoods() {
			Topicgoods({
					topic_id: 26,
					page_size: 2
				})
				.then(res => {
					this.Topicgoodslists = res.data.list
				}).catch(res => {
					console.log(res)
				});
		},
		getrecommend(e) { 
			
			
			
			goodsSkuPage({
				"nows_id":1,
				
			}).then(res => {
					this.jinr_lists = {
						'title': '金融专区',
						'value': {
							'goodsList': {
								'type': "goods",
								'value': {
									'list': res.data.list
								}
							},
							'bottomImg': {
								"type": "img",
								"value": {
									"url": "",
									"link": ""
								}
							},
							"title": {
								"type": "text",
								"value": {
									"text": "金融专区",
									"link": "",
									"color": ""
								}
							},
							"subTitle": {
								"type": "text",
								"value": {
									"text": "",
									"link": "",
									"color": ""
								}
							}
						},
						"block_name": "floor-style-2",
						"block_title": "样式2"
					}
				}).catch(err => {})
				
			goodsSkuPage({
				"nows_id":3,
				
			}).then(res => {
					this.jun_lists = {
						'title': '军需专区',
						'value': {
							'goodsList': {
								'type': "goods",
								'value': {
									'list': res.data.list
								}
							},
							'bottomImg': {
								"type": "img",
								"value": {
									"url": "",
									"link": ""
								}
							},
							"title": {
								"type": "text",
								"value": {
									"text": "军需专区",
									"link": "",
									"color": ""
								}
							},
							"subTitle": {
								"type": "text",
								"value": {
									"text": "",
									"link": "",
									"color": ""
								}
							}
						},
						"block_name": "floor-style-2",
						"block_title": "样式2"
					}
				}).catch(err => {})
				
			
			goodsSkuPage({
				"nows_id":2,
			}).then(res => {
					this.zqye_lists = {
						'title': '政企专区',
						'value': {
							'goodsList': {
								'type': "goods",
								'value': {
									'list': res.data.list
								}
							},
							'bottomImg': {
								"type": "img",
								"value": {
									"url": "",
									"link": ""
								}
							},
							"title": {
								"type": "text",
								"value": {
									"text": "政企专区",
									"link": "",
									"color": ""
								}
							},
							"subTitle": {
								"type": "text",
								"value": {
									"text": "",
									"link": "",
									"color": ""
								}
							}
						},
						"block_name": "floor-style-2",
						"block_title": "样式2"
					}
				}).catch(err => {})
			
			Topicgoods({
					tc: 1
				})
				.then(res => {
					if (res.code == 0) {
						this.recommend_lists = {
							'title': '特产名品',
							'value': {
								'goodsList': {
									'type': "goods",
									'value': {
										'list': res.data.list
									}
								},
								'bottomImg': {
									"type": "img",
									"value": {
										"url": "",
										"link": ""
									}
								},
								"title": {
									"type": "text",
									"value": {
										"text": "特产名品",
										"link": "",
										"color": ""
									}
								},
								"subTitle": {
									"type": "text",
									"value": {
										"text": "地方特产 特色专区",
										"link": "",
										"color": ""
									}
								}
							},
							"block_name": "floor-style-2",
							"block_title": "样式2"
						}
					}
				})
				.catch(res => {

				});
				
				
				
				tree({ 
				        level: 1,
						template:2
				    }).then(res => { 
				        if (res.code == 0 && res.data) {
							res.data.unshift({
								'category_id':0,
								'category_name':"猜你喜欢"
							});
							this.yi_category_lists = res.data
							this.yi_category_id = 0
							this.get_recommended(0)
				        }
				        
				    }).catch(err => {
				
				    })
					
					
				
					Topicgoods({
							topic_id: 27,
							page_size: 20
						})
						.then(res => {
							if (res.code == 0) {
								this.jiand_lists = {
									'title': '党建专区',
									'value': {
										'goodsList': {
											'type': "goods",
											'value': {
												'list': res.data.list
											}
										},
										'bottomImg': {
											"type": "img",
											"value": {
												"url": "",
												"link": ""
											}
										},
										"title": {
											"type": "text",
											"value": {
												"text": "党建专区",
												"link": "",
												"color": ""
											}
										},
										"subTitle": {
											"type": "text",
											"value": {
												"text": "党建专区 红色专区",
												"link": "",
												"color": ""
											}
										}
									},
									"block_name": "floor-style-2",
									"block_title": "样式2"
								}
							}
						})
						.catch(res => {
					
						});
					
					
		},
		get_recommended(category_id){ 
			this.yi_category_id = category_id;
			this.recommended_lists=[]
			goodsSkuPage({
				category_id:this.yi_category_id ,
				tops:1
			}).then(res => {
				this.recommended_lists = res.data.list
			}).catch(err => {})
							
		},
		getGiftIfi(e) {
			GiftIfi({}).then(res => {
				this.gift_ifi = res.data
				this.activities_id = this.gift_ifi[0]['id']
				this.giftIfiProduct()
			})
		},
		giftIfiProduct() {
			GiftIfiProduct({
				ifi_id: this.activities_id
			}).then(res => {
				this.gift_lists = res.data

			})
		},

		/*
		 * 解决秒杀组件复制出来的没有点击事件
		 */
		clickProps(e) {
			if (!e.target.dataset.obj) {
				return
			}
			let item = JSON.parse(e.target.dataset.obj);
			this.$router.pushToTab('/promotion/seckill-' + item.id)
		},
		countDownS_cb() {},
		countDownE_cb() {
			this.seckillText = "活动已结束"
		},
		getIndexGoodsList() {
			goodsSkuPage({})
				.then(res => {
					this.goodsList = res.data.list;
				})
				.catch(err => {})


		},
		//移入
		handleMouseEnterImages(type) {
			var yi_dinz_on = document.getElementsByClassName('yi_dinz_on')[type]
			yi_dinz_on.style.opacity = 0
			var yi_dinz_two = document.getElementsByClassName('yi_dinz_two')[type]
			yi_dinz_two.style.opacity = 1


		},
		//移除
		handleMouseLeaveImages(type) {
			var yi_dinz_two = document.getElementsByClassName('yi_dinz_two')[type]
			yi_dinz_two.style.opacity = 0
			var yi_dinz_on = document.getElementsByClassName('yi_dinz_on')[type]
			yi_dinz_on.style.opacity = 1
		},
		handlePageSizeChange(size) {
			this.pageSize = size
			this.getbrandsgoods()
		},
		handleCurrentPageChange(page) {
			this.currentPage = page
			this.getbrandsgoods()
		},
		getbrandsgoods() {
			brandList({
					page_size: this.pageSize,
					page: this.currentPage,
					site_id: this.siteId,
					is_recommend: 1
				})
				.then(res => {
					const {
						count,
						page_count,
						list,
					} = res.data
					this.total = count
					this.brandList = list;
				})
		},
		getBrandList() {
			this.getbrandsgoods()
			goodsSkuPage({
					news: 1,
					page_size:5
				}).then(res => {
					this.activities = res.data.list
					// this.activities = {
					// 	'title': '最新上架',
					// 	'value': {
					// 		'goodsList': {
					// 			'type': "goods",
					// 			'value': {
					// 				'list': res.data.list
					// 			}
					// 		},
					// 		'bottomImg': {
					// 			"type": "img",
					// 			"value": {
					// 				"url": "",
					// 				"link": ""
					// 			}
					// 		},
					// 		"title": {
					// 			"type": "text",
					// 			"value": {
					// 				"text": "最新上架",
					// 				"link": "",
					// 				"color": ""
					// 			}
					// 		},
					// 		"subTitle": {
					// 			"type": "text",
					// 			"value": {
					// 				"text": "",
					// 				"link": "",
					// 				"color": ""
					// 			}
					// 		}
					// 	},
					// 	"block_name": "floor-style-2",
					// 	"block_title": "样式2"
					// }
					
					
					
				}).catch(err => {})


		},
		getIndexbrandleftList() {
			adList({
					keyword: "NS_PC_LEFT_INDEX"
				})
				.then(res => {
					this.getIndexbrandleftList = res.data.adv_list

					for (let i = 0; i < this.getIndexbrandleftList.length; i++) {
						if (this.getIndexbrandleftList[i].adv_url) this.getIndexbrandleftList[i].adv_url = JSON
							.parse(this.getIndexbrandleftList[i].adv_url)
					}
				})
		},
		getAdList() {
			adList({
					keyword: "NS_PC_INDEX"
				})
				.then(res => {
					this.adList = res.data.adv_list
					for (let i = 0; i < this.adList.length; i++) {
						if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
					}
					this.backgroundColor = this.adList[0].background
					this.loadingAd = false
				})
				.catch(err => {
					this.loadingAd = false
				})
		},
		handleChange(curr, pre) {
			this.backgroundColor = this.adList[curr].background
		},
		/* 秒杀图片处理 */
		goodsImg(imgStr) {
			let imgs = imgStr.split(',');
			return imgs[0] ? this.$util.img(imgs[0], {
				size: 'mid'
			}) : this.defaultGoodsImage;
		},
		/**
		 * 广告位大图
		 */
		getBigAdList() {
			adList({
					keyword: "NS_PC_INDEX_MID_LEFT"
				})
				.then(res => {
					this.adLeftList = res.data.adv_list
					for (let i = 0; i < this.adLeftList.length; i++) {
						if (this.adLeftList[i].adv_url) this.adLeftList[i].adv_url = JSON.parse(this.adLeftList[i]
							.adv_url)
					}
					this.loadingAd = false
				})
				.catch(err => {
					this.loadingAd = false
				})
		},
		/**
		 * 广告位小图
		 */
		getSmallAdList() {
			adList({
					keyword: "NS_PC_INDEX_MID_RIGHT"
				})
				.then(res => {
					this.adRightList = res.data.adv_list
					for (let i = 0; i < this.adRightList.length; i++) {
						if (this.adRightList[i].adv_url) this.adRightList[i].adv_url = JSON.parse(this.adRightList[
							i].adv_url)
					}
					this.loadingAd = false
				})
				.catch(err => {
					this.loadingAd = false
				})
			adList({
					keyword: "INDEX_TC"
				})
				.then(res => {
					this.adChoiceList = res.data.adv_list
					for (let i = 0; i < this.adChoiceList.length; i++) {
						if (this.adChoiceList[i].adv_url) this.adChoiceList[i].adv_url = JSON.parse(this
							.adChoiceList[
								i].adv_url)
					}
					this.loadingAd = false
				})
				.catch(err => {
					this.loadingAd = false
				})
		},
		getNotice() {
			noticesList({
					page: 1,
					page_size: 5,
					receiving_type: "web"
				})
				.then(res => {
					this.noticeList = res.data.list
				})
				.catch(err => err)
		},
		//获取优惠券列表
		getCanReceiveCouponQuery() {
			couponList({
					activeName: "second",
					site_id: this.siteId
				})
				.then(res => {
					let data = res.data
					this.couponList = []
					if (data != null) {
						for (let i = 0; i < data.list.length; i++) {
							if (i < 5) {
								this.couponList.push(data.list[i])
							}
						}
						this.couponList.forEach(v => {
							v.useState = 0
						})
					}
				})
				.catch(err => {})
		},
		/**
		 * 领取优惠券
		 */
		receiveCoupon(item, index) {
			if (this.isSub) return
			this.isSub = true

			var data = {
				site_id: item.site_id,
				activeName: "second",
				platformcoupon_type_id: item.platformcoupon_type_id
			}

			receiveCoupon(data)
				.then(res => {
					var data = res.data
					let msg = res.message
					if (res.code == 0) {
						msg = "领取成功"
						this.$message({
							message: msg,
							type: "success"
						})
					} else {
						this.$message({
							message: msg,
							type: "warning"
						})
					}
					let list = this.couponList
					if (res.data.is_exist == 1) {
						for (let i = 0; i < list.length; i++) {
							if (list[i].platformcoupon_type_id == item.platformcoupon_type_id) {
								list[i].useState = 1
							}
						}
					} else {
						for (let i = 0; i < list.length; i++) {
							if (list[i].platformcoupon_type_id == item.platformcoupon_type_id) {
								list[i].useState = 2
							}
						}
					}

					this.isSub = false
					this.$forceUpdate()
				})
				.catch(err => {
					if (err.message == 'token不存在') {
						this.$router.pushToTab('/login')
					} else {
						this.$message.error(err.message);
					}
					this.isSub = false
				})
		},
		/**
		 * 点击优惠券
		 */
		couponTap(item, index) {
			if (item.useState == 0) this.receiveCoupon(item, index)
			else this.toGoodsList(item)
		},
		/**
		 * 去购买
		 */
		toGoodsList(item) {
			if (item.use_scenario != 1) {
				this.$router.push({
					path: "/list",
					query: {
						platformcouponTypeId: item.platformcoupon_type_id
					}
				})
			} else {
				this.$router.push("/list")
			}
		},
		/**
		 * 限时秒杀
		 */
		getTimeList() {
			timeList()
				.then(res => {
					if (res.code == 0 && res.data) {
						let time = new Date(res.timestamp * 1000)
						let currentTimes = time.getHours() * 60 * 60 + time.getMinutes() * 60 + time.getSeconds()
						res.data.list.forEach((v, k) => {
							if (v.seckill_start_time <= currentTimes && currentTimes < v.seckill_end_time) {
								let seckillId = v.id
								this.getGoodsList(seckillId)

								let endTime = parseInt(time.getTime() / 1000) + (v.seckill_end_time -
									currentTimes)
								this.seckillTimeMachine = {
									currentTime: res.timestamp,
									startTime: res.timestamp,
									endTime: endTime
								}
							}
						})
					}
				})
				.catch(err => {
					this.$message.error(err.message)
				})
		},
		/**
		 * 秒杀商品
		 */
		getGoodsList(id) {
			goodsPage({
					page_size: 0,
					seckill_id: id,
					site_id: this.siteId
				})
				.then(res => {
					if (res.code == 0 && res.data.list) {
						this.listData = res.data.list
					}
				})
				.catch(err => {})
		},
		/**
		 * 图片加载失败
		 */
		imageError(index) {
			this.listData[index].sku_image = this.defaultGoodsImage
		},
		/**
		 * 图片加载失败
		 */
		adLeftImageError(index) {

			if (this.adLeftList) {
				this.adLeftList[index].adv_image = this.defaultGoodsImage
			}
		},
		/**
		 * 图片加载失败
		 */
		adRightImageError(index) {
			if (this.adRightList) {
				this.adRightList[index].adv_image = this.defaultGoodsImage
			}
		},
		getFloors() {
			floors()
				.then(res => {
					this.floorList = res.data;
				})
				.catch(err => {})
		},
		getFloatLayer() {
			floatLayer()
				.then(res => {
					if (res.code == 0 && res.data) {
						this.floatLayer = res.data
						this.floatLayer.link = JSON.parse(this.floatLayer.url)
						// 弹框形式，首次弹出 1，每次弹出 0

						if (!this.floatLayer.img_url) return

						if (parseInt(this.floatLayer.number) == 1) {
							var index_popwindow_count = this.$store.state.app.indexFloatLayerNum;
							if (index_popwindow_count == '' || index_popwindow_count == 1) {
								this.floatLayer.is_show = true
								this.$store.commit("app/SET_FLOAT_LAYER", 1)
							}
						} else if (parseInt(this.floatLayer.number) == 0) {
							this.floatLayer.is_show = true
							this.$store.commit("app/SET_FLOAT_LAYER", 0)
						}
					}
				})
				.catch(err => err)
		},
		closeFloat() {
			this.floatLayer.is_show = false
			this.$forceUpdate()
			this.$store.commit("app/SET_FLOAT_LAYER", -1)
		},
		// 监听滚动条
		handleScroll() {
			var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

			if (scrollTop >= 680) {
				this.isShow = true
			} else {
				this.isShow = false
			}
		},
		destroyed() {
			// 离开该页面需要移除这个监听的事件，不然会报错
			window.removeEventListener("scroll", this.handleScroll)
		},
		/**
		 * 默认搜索类型（goods/shop）
		 */
		getDefaultSearchWords() {
			apiDefaultSearchWords({}).then(res => {
				if (res.code == 0 && res.data.words) {
					this.defaultSearchWords = res.data.words
				}
			})
		},
		handleCommand(command) {
			this.searchType = command
		},
		search() {
			if (this.searchType == "goods") this.$router.push({
				path: "/list",
				query: {
					keyword: this.keyword
				}
			})
			else this.$router.push({
				path: "/street",
				query: {
					keyword: this.keyword
				}
			})
		},
		onHover(obj) {
			this.activities_id = obj
			this.giftIfiProduct()
		}
	}
}