<template>
	<div class="floor-style-2">
		<div class="head-wrap" v-if="data.value.title.value.text" style="position: relative;">
			<h2 v-if="data.value.title.value.text" @click="$router.pushToTab(data.value.title.value.link.url)"
				:style="{ color: data.value.title.value.color }">{{ data.value.title.value.text }}</h2>
			<p v-if="data.value.subTitle.value.text" @click="$router.pushToTab(data.value.subTitle.value.link.url)"
				:style="{ color: data.value.subTitle.value.color }">{{ data.value.subTitle.value.text }}</p>
				<p style="position: absolute;right: 0;bottom: 10px;" @click="miaos()">更多商品>></p>
		</div>
		<div class="body-wrap">
			<div class="carousel-container">
				<ul  class="goods-list carousel-list" :style="{ transform: transformStyle }">
					<li :style="liwidths" v-for="(item, index) in data.value.goodsList.value.list" :key="index" :title="item.goods_name"
						@click="goSku(item.sku_id)" class="carousel-item">
						<div class="img-wrap"><img alt="商品图片" :src="$img(item.sku_image)" @error="imageError(index)" />
						</div>
						<h3>{{ item.goods_name }}</h3>
						<p class="price" v-if="data.value.title.value.text=='限时秒杀'" style=" height:25px;border: 1px solid rgb(255, 84, 123);">
							<span
								style="display: block; width: 49%; float: left; background: rgb(255, 84, 123); color: rgb(255, 255, 255); height: 25px;">马上抢</span>
							<span class="num"
								style="display: block; width: 49%; float: left; height: 25px;">{{ item.discount_price }}元</span>
							<div style="clear: both;"></div>
						</p>
						<p v-if="data.value.title.value.text=='特产名品'" class="price" style="text-align: left;" >
							{{ item.discount_price }}元
						</p>
					</li>
				</ul>
			</div>
		</div>
		<div class="bottom-wrap" v-if="data.value.bottomImg.value.url">
			<img :src="$img(data.value.bottomImg.value.url)"
				@click="$router.pushToTab(data.value.bottomImg.value.link.url)" />
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	export default {
		name: 'floor-style-2',
		props: {
			data: {
				type: Object
			},
			nums:{
				type:Number
			}
		},
		data() {
			return {
				transformStyle: '',
				currentIndex:0,
				liwidths:0
			};
		},
		//data.value.goodsList.value.list
		created() {  
			this.liwidths = 'width:'+100/this.nums +'%'
		},
		computed: {
			...mapGetters(['defaultGoodsImage'])
		},
		methods: {
			goSku(skuId) {
				this.$router.pushToTab('/sku-' + skuId);
			},
			miaos() { 
				//list?category_id=55&level=1
				if(this.data.value.title.value.text=='限时秒杀'){ 
					this.$router.pushToTab('/list?ms=1');
				}else if(this.data.value.title.value.text=='特产名品'){
					this.$router.pushToTab('/special');
				}else if(this.data.value.title.value.text=='政企专区'){
					this.$router.pushToTab('/list?nows_id=2');
					//nows_id
					
				}else if(this.data.value.title.value.text=='军需专区'){
					this.$router.pushToTab('/list?nows_id=3');
				}else if(this.data.value.title.value.text=='金融专区'){ 
					this.$router.pushToTab('/list?nows_id=1');
				}else if(this.data.value.title.value.text=='党建专区'){ 
					this.$router.pushToTab('/giftStreet?id=27');
				}
				
			},
			imageError(index) {
				this.data.value.goodsList.value.list[index].sku_image = this.defaultGoodsImage;
			},
			updateVisibleItems() {
				var start = this.currentIndex;
				var end =this.data.value.goodsList.value.list.length - this.nums;
				var imagwidth = document.getElementsByClassName('carousel-item')[0].clientWidth+20;
				if(start < end){ 
					this.currentIndex ++;
				}else{ 
					this.currentIndex =0; 
					start =0;
				}
				this.transformStyle = `translateX(-${this.currentIndex * imagwidth}px)`;
				
			}
		},
		mounted() {
			this.updateVisibleItems();
			setInterval(() => {
				this.updateVisibleItems();
			}, 3000);
			
			
		},
		
	};
</script>

<style lang="scss">
	.floor-style-2 {
		.head-wrap {

			h2 {
				line-height: 30px;
				color: #333;
				padding: 10px;
				font-size: 22px;
				cursor: pointer;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				text-align: left;
				display: inline-block;
			}

			p {
				color: #b0b0b0;
				padding: 0 10px;
				font-size: 14px;
				cursor: pointer;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				// margin-bottom: 20px;
				text-align: left;
				display: inline-block;
			}
		}

		.body-wrap {
			background: #fff;

			.goods-list {
				display: flex;
				// flex-wrap: wrap;

				li {
				
					margin-left: 15px;
					margin-bottom: 15px;
					background: #fff;
					cursor: pointer;
					padding: 10px 0;
					transition: all 0.2s linear;

					&:nth-child(5n + 1) {
						// margin-left: 0;
					}

					&:hover {
						z-index: 2;
						-webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
						box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
						-webkit-transform: translate3d(0, -2px, 0);
						transform: translate3d(0, -2px, 0);
					}

					.img-wrap {
						width: 160px;
						height: 160px;
						margin: 0 auto 18px;
						text-align: center;
						line-height: 160px;

						img {
							max-width: 100%;
							max-height: 100%;
						}
					}

					h3 {
						font-size: 12px;
						text-align: center;
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
						margin: 5px 15px;
						width: 160px;
					}

					.desc {
						margin: 0 30px 10px;
						height: 20px;
						font-size: 12px;
						color: #b0b0b0;
						text-align: center;
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
					}

					.price {
						margin: 0 10px 14px;
						text-align: center;
						color: $base-color;

						del {
							margin-left: 0.5em;
							color: #b0b0b0;
						}
					}
				}
			}
		}

		.bottom-wrap {
			margin-top: 10px;
			width: $width;
			height: 118px;
			cursor: pointer;
			overflow: hidden;

			img {
				max-width: 100%;
			}
		}
	}

	.carousel-container {
		overflow: hidden;
	}

	.carousel-list {
		display: flex;
		transition: transform 0.5s;
	}

	.carousel-item {
		// flex: 0 0 20%;
		text-align: center;
	}
</style>