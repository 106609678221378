<template>
	<div>
		<!-- 首页固定区 -->
		<div class="index-wrap" :style="indexBandImages">
			<div class="index">
				<div class="banner">
					<el-carousel height="500px" arrow="hover" v-loading="loadingAd" @change="handleChange">
						<el-carousel-item v-for="item in adList" :key="item.adv_id" v-if="adList.length>0">
							<el-image :src="$img(item.adv_image)" fit="cover"
								@click="$router.pushToTab(item.adv_url.url)" />
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="news"
					style="display: flex;flex-direction: column;justify-content: space-between;margin-left: 5px;">

					<div class="login-wrap">
						<div class="avtar">
							<router-link to="/member/info">
								<img v-if="member.headimg" :src="$img(member.headimg)"
									@error="member.headimg = defaultHeadImage" />
								<img v-else :src="require('@/assets/images/memberdefault.png')" />
							</router-link>
						</div>
						<div class="btn" v-if="!member">
							<router-link to="/login" class="login">登录</router-link>
							<router-link to="/register" class="register">注册</router-link>
						</div>
						<div class="memeber-name" v-else>{{ member.nickname }}</div>
					</div>

					<div class="server-wrap">
						<div class="item-wrap">
							<div class="item">
								<a href="/member/collection" target="_blank">
									<img :src="require('@/assets/images/server-wrap-1.png')" alt="">
									<span>我的收藏</span>
								</a>
							</div>
							<div class="item">
								<a href="/member/index" target="_blank">
									<img :src="require('@/assets/images/server-wrap-2.png')" alt="">
									<span>我的订单</span>
								</a>
							</div>
						</div>
					</div>

					<div class="Membership">
						<router-link to="/login" class="login">
							<img :src="require('@/assets/images/membership.png')" alt="">
						</router-link>
					</div>

					<div class="img" v-if="getIndexbrandleftList[0]" style="height: 224px;overflow: hidden;">
						<el-image :src="$img(getIndexbrandleftList[0]['adv_image'])" fit="cover"
							@click="$router.pushToTab(getIndexbrandleftList[0]['adv_url']['url'])"
							style="width: auto;height: 224px;width: 100%;" />

					</div>


				</div>
			</div>
		</div>

		<div class="content">
			<!-- 领券中心 -->
			<div class="content-div" v-if="addonIsExit.coupon == 1 && couponList.length > 0 && (city.id == 0 || !city)">
				<div class="coupon">
					<div class="coupon-title">
						<p class="coupon-font">领券中心</p>
						<p class="coupon-en">coupon</p>
						<p class="coupon-more" @click="$router.push('/coupon')">
							<span>更多</span>
							<i class="iconfont iconarrow-right"></i>
						</p>
					</div>
					<ul class="coupon-list">
						<li v-for="(item, index) in couponList" :key="index">
							<p class="coupon-price ns-text-color">
								￥
								<span>{{ item.money }}</span>
							</p>
							<p class="coupon-term">满{{ item.at_least }}可用</p>
							<p class="coupon-receive ns-text-color" @click="couponTap(item, index)">
								<span v-if="item.useState == 0">立即领取</span>
								<span v-else>去使用</span>
								<i class="iconfont iconarrow-right"></i>
							</p>
						</li>
					</ul>
				</div>
			</div>

			<!-- 广告 -->

			<div class="content-div" v-if="adLeftList.length > 0 || adRightList.length > 0">
				<div style="text-align: center;">
					<img :src="$img('public/static/img/cheap-til.png')" />
				</div>
				<div class="ad-wrap">
					<div class="ad-big" style="height: auto;">
						<div style="margin: 0 10px;" class="ad-big-img" v-if="adLeftList.length>0"
							v-for="(item, index) in adLeftList" :key="index">
							<el-image :src="$img(item.adv_image)" fit="cover" @error="adLeftImageError(index)"
								@click="$router.pushToTab(item.adv_url.url)"></el-image>
						</div>
					</div>

				</div>
			</div>

			<!-- 限时秒杀 -->
			<div class="content-div" v-if="addonIsExit.seckill == 1 && listData.length > 0 && (city.id == 0 || !city)">
				<div class="seckill-wrap">
					<div class="seckill-time">
						<div class="seckill-time-left">
							<i class="iconfont iconmiaosha1 ns-text-color"></i>
							<span class="seckill-time-title ns-text-color">限时秒杀</span>
							<span>{{ seckillText }}</span>
							<count-down class="count-down" v-on:start_callback="countDownS_cb()"
								v-on:end_callback="countDownE_cb()" :currentTime="seckillTimeMachine.currentTime"
								:startTime="seckillTimeMachine.startTime" :endTime="seckillTimeMachine.endTime"
								:dayTxt="'：'" :hourTxt="'：'" :minutesTxt="'：'" :secondsTxt="''"></count-down>
						</div>
						<div class="seckill-time-right" @click="$router.push('/promotion/seckill')">
							<span>更多商品</span>
							<i class="iconfont iconarrow-right"></i>
						</div>
					</div>
					<div class="seckill-content" @click="clickProps($event)">
						<vue-seamless-scroll :data="listData" :class-option="optionLeft" class="seamless-warp2">
							<ul class="item" :style="{ width: 250 * listData.length + 'px' }">
								<li v-for="(item, index) in listData" :key="index">
									<div class="seckill-goods" :data-obj="JSON.stringify(item)">
										<div class="seckill-goods-img" :data-obj="JSON.stringify(item)">
											<img :src="goodsImg(item.goods_image)" @error="imageError(index)"
												:data-obj="JSON.stringify(item)" />
										</div>
										<p :data-obj="JSON.stringify(item)">{{ item.goods_name }}</p>
										<div class="seckill-price-wrap" :data-obj="JSON.stringify(item)">
											<p class="ns-text-color" :data-obj="JSON.stringify(item)">
												￥
												<span :data-obj="JSON.stringify(item)">{{ item.seckill_price }}</span>
											</p>
											<p class="primary-price" :data-obj="JSON.stringify(item)">￥{{ item.price }}
											</p>
										</div>
									</div>
								</li>
							</ul>
						</vue-seamless-scroll>
					</div>
				</div>
			</div>

			<!-- 会员活动 -->
			<div class="yi_activities">
				<div class="p_l_title floor" style="text-align: center;">
					<img :src="require('@/assets/images/yi_activities.png')" alt="">
				</div>

				<!-- <div class="lists floor">
					
					<div>
						<floor-style-2 :nums=6  :data="activities" />
					</div>
				</div>
				
				 -->

				<div class="recommended">

					<div class="floor">
						<div class="floor-style-2">
							<div class="head-wrap" style="position: relative;">
								<h2>最新上架</h2>
								<p></p>
								<p style="position: absolute; right: 0px; bottom: 10px;"
									@click="$router.pushToTab({ path: '/list', query: { news: 1 } })">更多商品&gt;&gt;</p>
							</div>
						</div>
						<div class="product" style="margin-top: 0;">

							<div class="lis" v-for="(item, index) in activities" :key="index"
								@click="$router.pushToTab({ path: '/sku-' + item.sku_id })">
								<div class="boxs">
									<div class="img-wrap">

										<img :src="$img(item.sku_image)" />
									</div>
									<h3>{{item.goods_name}}</h3>
									<p class="price">
										<span>{{ item.discount_price }}</span>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div style="clear:both"> </div>
			</div>
			<!-- 楼层区 -->
			<div class="content-div " v-if="(city.id == 0 || !city)">
				<div class="floor">
					<div v-for="(item, index) in floorList" :key="index" class="floor_item">
						<!-- <floor-style-1 v-if="item.block_name == 'floor-style-1'" :data="item" /> -->
						<floor-style-2 :nums=5 v-if="item.block_name == 'floor-style-2'" :data="item" />
						<!-- <floor-style-3 v-if="item.block_name == 'floor-style-3'" :data="item" /> -->
					</div>
				</div>
			</div>

			<div class="yi_choice">
				<div class="p_l_title floor" style="text-align: center;">
					<img :src="require('@/assets/images/yi_jiand.png')" alt="">
				</div>
				<div class="lists floor">
					<div class="floor_item">
						<floor-style-2 :nums=5 :data="jiand_lists"
							v-if="jiand_lists.block_name == 'floor-style-2'" />
					</div>
				</div>

			</div>


			<div class="yi_choice">
				<div class="p_l_title floor" style="text-align: center;">
					<img :src="require('@/assets/images/yi_choice.png')" alt="">
				</div>
				<div class="lists floor">
					<div class="floor_item">
						<floor-style-2 :nums=5 :data="recommend_lists"
							v-if="recommend_lists.block_name == 'floor-style-2'" />
					</div>
					<div class="brands">
						<div class="ad-big-img" v-if="adChoiceList.length>0" v-for="(item, index) in adChoiceList"
							:key="index">
							<el-image :src="$img(item.adv_image)" fit="cover" @error="adLeftImageError(index)"
								@click="$router.pushToTab(item.adv_url.url)"></el-image>
						</div>
					</div>
				</div>

			</div>

			<div class="yi_dinz">
				<div class="p_l_title floor" style="text-align: center;">
					<img :src="require('@/assets/images/yi_customized.png')" alt="">
				</div>
				<div class="lists floor">
					<div class="left">
						<div @mouseover="handleMouseEnterImages(0)" @mouseout="handleMouseLeaveImages(0)"
							@click="$router.pushToTab('/customized')">
							<img :src="require('@/assets/images/yi_index_dz.png')" class="yi_dinz_on" />
							<img :src="require('@/assets/images/yi_index_dz_on.png')" class="yi_dinz_two" />
						</div>
						<div @mouseover="handleMouseEnterImages(1)" @mouseout="handleMouseLeaveImages(1)"
							@click="$router.pushToTab('/giftStreet?id=26')">
							<img :src="require('@/assets/images/yi_index_lpj.png')" class="yi_dinz_on" />
							<img :src="require('@/assets/images/yi_index_lpj_on.png')" class="yi_dinz_two" />
						</div>
					</div>
					<div class="rights">
						<div class="tops">
							<div class="title" @click="$router.pushToTab({ path: '/brand'})">授权品牌推荐>></div>
							<div class="brands_lists">
								<div v-for="(item, index) in brandList" :key="item.id"
									@click="$router.pushToTab({ path: '/list', query: { brand_id: item.brand_id } })">
									<el-image fit="scale-down" :src="$img(item.image_url)" lazy
										@error="imageError(index)"></el-image>
								</div>
							</div>
							<div class="pager">
								<el-pagination background :pager-count="5" :total="total" prev-text="上一页"
									next-text="下一页" :current-page.sync="currentPage" :page-size.sync="pageSize"
									@size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"
									hide-on-single-page></el-pagination>
							</div>

						</div>
						<div class="product_lists">
							<div class="title" @click="$router.pushToTab('/giftStreet?id=26')">节日福利>></div>

							<div class="product">
								<ul>
									<li style="padding:3px" v-for="(item, index) in Topicgoodslists" :key="index"
										:title="item.goods_name"
										@click="$router.pushToTab({ path: '/sku-' + item.sku_id })">
										<div class="img-wrap">

											<img :title="item.goods_name" :src="$img(item.sku_image)" />
										</div>
										<!-- <h3>{{item.goods_name}}</h3> -->

									</li>
								</ul>
								<div style="clear: both;"></div>
							</div>

						</div>


					</div>
				</div>

			</div>



			<div class="company_cg">
				<div class="p_l_title floor" style="text-align: center;">
					<img :src="require('@/assets/images/company_cg.png')" alt="">
				</div>

				<div class="floor">
					<div style="margin-bottom: 20px;background: #fff;">
						<floor-style-2 :nums=5 :data="zqye_lists" v-if="zqye_lists.block_name == 'floor-style-2'" />
					</div>
					<div style="margin-bottom: 20px;background: #fff;">
						<floor-style-2 :nums=5 :data="jun_lists" v-if="jun_lists.block_name == 'floor-style-2'" />
					</div>
					<div style="margin-bottom: 20px;background: #fff;">
						<floor-style-2 :nums=5 :data="jinr_lists" v-if="jinr_lists.block_name == 'floor-style-2'" />
					</div>
				</div>
			</div>

			<div class="recommended">
				<div class="p_l_title floor" style="text-align: center;">
					<img :src="require('@/assets/images/recommended.png')" alt="">
				</div>

				<div class="floor">
					<div class="cateslists">
						<div v-for="item in yi_category_lists" :key="item.category_id"
							@click="get_recommended(item.category_id)">
							<a href="javascript:;"
								:class="yi_category_id==item.category_id?'on':''">{{item.category_name}}</a>
						</div>
					</div>
					<div class="product">
						<div class="lis" v-for="(item, index) in recommended_lists" :key="index"
							@click="$router.pushToTab({ path: '/sku-' + item.sku_id })">
							<div class="boxs">
								<div class="img-wrap">

									<img :src="$img(item.sku_image)" />
								</div>
								<h3>{{item.goods_name}}</h3>
								<p class="price">
									<span>{{ item.discount_price }}</span>
								</p>
							</div>
						</div>
					</div>



				</div>
			</div>
			<!-- 浮层区 -->
			<div class="floatLayer-wrap" v-if="floatLayer.is_show && city.id == 0">
				<div class="floatLayer">
					<div class="img-wrap">
						<img :src="$img(floatLayer.img_url)" @click="$router.pushToTab(floatLayer.link.url)" />
					</div>
					<i class="el-icon-circle-close" @click="closeFloat"></i>
				</div>
			</div>

			<!-- 悬浮搜索 -->
			<div class="fixed-box" :style="{ display: isShow ? 'block' : 'none' }">
				<div class="header-search">
					<el-row>
						<el-col :span="6">
							<router-link to="/" class="logo-wrap"><img :src="$img(siteInfo.logo)" /></router-link>
						</el-col>
						<el-col :span="11">
							<div class="in-sousuo">
								<div class="sousuo-box">
									<el-dropdown @command="handleCommand" trigger="click">
										<span class="el-dropdown-link">
											{{ searchTypeText }}
											<i class="el-icon-arrow-down"></i>
										</span>
										<el-dropdown-menu slot="dropdown">
											<el-dropdown-item command="goods">商品</el-dropdown-item>
											<el-dropdown-item command="shop">店铺</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
									<input type="text" :placeholder="defaultSearchWords" v-model="keyword"
										@keyup.enter="search" maxlength="50" />
									<el-button type="primary" size="small" @click="search">搜索</el-button>
								</div>
							</div>
						</el-col>
						<el-col :span="3">
							<div class="cart-wrap">
								<router-link class="cart" to="/cart">
									<span>我的购物车</span>
									<el-badge v-if="cartCount" :value="cartCount" type="primary"><i
											class="iconfont icongouwuche"></i></el-badge>
									<i v-else class="iconfont icongouwuche"></i>
								</router-link>
							</div>
						</el-col>
						<el-col :span="4">
							<div class="applets-images" style="text-align: center;">
								<el-image v-if="siteInfo.web_qrcode" :src="$img(siteInfo.web_qrcode)"
									@error="imageError" fit="contain">

								</el-image>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import floorStyle1 from './components/floor-style-1.vue';
	import floorStyle2 from './components/floor-style-2.vue';
	import floorStyle3 from './components/floor-style-3.vue';
	import index from './_index/index.js';
	export default {
		name: 'index',
		components: {
			floorStyle1,
			floorStyle2,
			floorStyle3
		},
		mixins: [index]
	};
</script>

<style lang="scss" scoped>
	@import './_index/index.scss';
</style>

<style lang="scss" scoped>
	.count-down {
		span {
			display: inline-block;
			width: 22px;
			height: 22px;
			line-height: 22px;
			text-align: center;
			background: #383838;
			color: #ffffff;
			border-radius: 2px;
		}
	}

	.more {
		width: 1200px;
		margin: auto;
		display: flex;
		justify-content: flex-end;

		a {
			color: #ff547b
		}
	}

	.goods-info {
		width: 1200px;
		margin: 5px auto 40px;
		display: flex;
		flex-wrap: wrap;

		.item {
			width: 202px;
			margin: 10px 20px 0 0;
			border: 1px solid #eeeeee;
			padding: 10px;
			position: relative;

			&:nth-child(5 n) {
				margin-right: initial !important;
			}

			&:hover {
				border: 1px solid $base-color;
			}

			.img-wrap {
				width: 198px;
				height: 198px;
				cursor: pointer;
			}

			.goods-name {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				cursor: pointer;

				&:hover {
					color: $base-color;
				}
			}

			.price-wrap {
				display: flex;
				align-items: center;

				.price {
					display: flex;
					color: $base-color;
					font-size: $ns-font-size-lg;

					p {
						font-size: $ns-font-size-base;
						display: flex;
						align-items: flex-end;
					}
				}

				.market-price {
					color: #838383;
					text-decoration: line-through;
					margin-left: 10px;
				}
			}

			.sale-num {
				display: flex;
				color: #838383;

				p {
					color: #4759a8;
				}
			}

			.saling {
				display: flex;
				font-size: $ns-font-size-sm;
				line-height: 1;

				.free-shipping {
					background: $base-color;
					color: #ffffff;
					padding: 3px 4px;
					border-radius: 2px;
					margin-right: 5px;
				}

				.promotion-type {
					color: $base-color;
					border: 1px solid $base-color;
					display: flex;
					align-items: center;
					padding: 1px;
				}
			}
		}
	}

	.Membership {

		padding-bottom: 10px;

	}
</style>